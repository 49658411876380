import { Component } from '@angular/core';
import { LoadingService } from '../../services/loading.service';
import { EnterMainLoadingMask } from '@shared/components/main-loading-mask/animations/enter-main-loading-mask';

@Component({
  selector: 'app-main-loading-mask',
  templateUrl: './main-loading-mask.component.html',
  styleUrls: ['./main-loading-mask.component.scss'],
  animations: [
      EnterMainLoadingMask
  ]
})
export class MainLoadingMaskComponent {
  constructor(
    protected readonly loadingService: LoadingService,
  ) {
  }
}
