import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    /**
     * Se escucha para mostrar el logo de GFSYS,
     */
    public readonly loading = new BehaviorSubject<boolean>(false);
    /**
     * Se escucha para usar en un componente.
     */
    /**
     * Contains in-progress loading requests
     */
    public loadingMap = {};

    constructor(
        private readonly router: Router,
    ) {
        this.listenRouter();
    }

    /**
     * Envía el estado del proceso, luego determina si terminó todos los procesos o no.
     * Este determinado por loading si mostrar el gif de GFSYS o no.
     *
     * @param loading
     * @param url
     */
    public setLoading(loading: boolean, url: string): void {
        if (!url) {
            throw new Error(
                'The request URL must be provided to the LoadingService.setLoading function',
            );
        }
        if (loading) {
            this.loadingMap[url] = loading;
            this.loading.next(true);
        } else if (!loading && this.loadingMap[url]) {
            delete this.loadingMap[url];
        }
        if (Object.keys(this.loadingMap).length === 0) {
            this.loading.next(false);
        }
    }

    /**
     * Se suscribe a la navegación en la app y determina que ruta estar cargando o no.
     *
     * @private
     */
    private listenRouter(): void {
        this.router.events.subscribe({
            next: (value) => {
                if (value instanceof NavigationStart) {
                    this.setLoading(true, value.url);
                }
                if (
                    value instanceof NavigationEnd ||
                    value instanceof NavigationCancel ||
                    value instanceof NavigationError
                ) {
                    this.setLoading(false, value.url);
                }
            },
        });
    }

    /**
     * Escucha a un observable para mostrar el logo de GFSYS mientras se resuelve el observable.
     */
    public listen<T>(): (source: Observable<T>) => Observable<T> {
        return (source) => {
            let ref: string;
            return defer(() => {
                ref = Object.keys(this.loadingMap).length.toString();
                this.setLoading(true, `loader-${ref}`);
                return source.pipe(
                    tap({
                        next: _ => this.setLoading(false, `loader-${ref}`),
                        error: _ => this.setLoading(false, `loader-${ref}`),
                    })
                );
            });
        };
    }
}
