import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { MainLoadingMaskComponent } from '@shared/components/main-loading-mask/main-loading-mask.component';

/**
 * Anima la entrada de un componente, primero estará con opacity = 0, luego de x ms a 1
 */
export const EnterMainLoadingMask = trigger('EnterMainLoadingMask', [
    state(
        '*',
        style({
            position: 'fixed',
            width: '100%',
            height: '100%',
            transitionProperty: 'all',
            transitionTimingFunction: 'ease',
        }),
    ),
    transition(':enter', [
        style({opacity: 0, position: 'fixed'}),
        animate('100ms', style({opacity: 1})),
    ]),
    transition(':leave', [
        style({opacity: 1, position: 'fixed'}),
        animate('100ms', style({opacity: 0})),
    ]),
]);
