import { ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material";

@Injectable({
  providedIn: "root",
})
export class Utilities {
  constructor(
    private toastrService: ToastrService,
    private _snackBar: MatSnackBar
  ) {}
  // Muestra un error en rojo
  showError(error, mensaje, timeout = 10000) {
    this.toastrService.error(`${mensaje}`, `${error}`, {
      timeOut: timeout,
      positionClass: "toast-bottom-center",
    });
  }
  // Muestra un mensaje en verde
  showSuccess(success, mensaje, timeout = 10000) {
    this.toastrService.success(`${mensaje}`, `${success}`, {
      timeOut: timeout,
      positionClass: "toast-bottom-center",
    });
  }
  // Muestra un mensaje en azul claro
  showInfo(success, mensaje, timeout = 10000) {
    this.toastrService.info(`${mensaje}`, `${success}`, {
      timeOut: timeout,
      positionClass: "toast-bottom-center",
    });
  }
  // Muestra un mensaje en gris
  showNotification(masage: string, timeout = 10000) {
    this._snackBar.open(masage, null, {
      duration: timeout,
    });
  }

  // Muestra un mensaje de advertencia
  showWarning(titulo, mensaje, timeout = 10000) {
    this.toastrService.warning(`${mensaje}`, `${titulo}`, {
      timeOut: timeout,
      positionClass: "toast-bottom-center",
    });
  }

  scrollToError(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      const offsetTop = element.getBoundingClientRect().top;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const clientTop = document.documentElement.clientTop || document.body.clientTop || 0;
      const top = offsetTop + scrollTop - clientTop;
      const middleOfScreen = top - (window.innerHeight / 2);

      window.scrollTo({ top: middleOfScreen, behavior: 'smooth' });


    }
  }
}
