import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxDataGridComponent } from "devextreme-angular";
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { ChangeDetectorRef } from '@angular/core';
import { PendingVouchers } from '../../../models/client/IncomePaymentProofMain';
import { IncomePaymentProofMainProvider } from 'src/app/providers/client/income_payment_proof_main.provider';
import { ViewPaginator } from 'src/app/models/client/ViewPaginator';
import draggable from 'devextreme/ui/draggable';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  templateUrl: './income-payment-vouchers.modal.html',
  styleUrls: ['./income-payment-vouchers.modal.scss']
})

export class IncomePaymentVouchersModal {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('dataGridInvoices', { static: false }) dataGridInvoices: DxDataGridComponent;
  public header_columns = ["check","client_name",
    "expiration",
    "remaining_balance",
    "register_date",
    "document",
    "total",
    "retention",
    "credits"];
    public filter = {
      date: '',
      document: ''
    }
    public allow_paginate:boolean=false;
    public paginate:boolean=false;
    public showSpinner:boolean=false;
  constructor(
    public dialogRef: MatDialogRef<IncomePaymentVouchersModal>,
    private _snackBar: MatSnackBar,
    private cdRef: ChangeDetectorRef,
    private incomePaymentProofMainService: IncomePaymentProofMainProvider,
    @Inject(MAT_DIALOG_DATA) public data: {selected: any, pending_vouchers: PendingVouchers[], clients_seleted: any[], date : any, enterprise:string,
      invoice: boolean, exchange?: boolean,paginate?:boolean}
  ) {
    debugger
    console.log('data modal', this.data);
    if (data.paginate==true) {
        this.allow_paginate=true
        this.paginate=true
    }
    for (const item of this.data.pending_vouchers) {
      let data_2 = {}
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          data_2[key] = item[key];
        }
      }
      this.pending_vouchers.push(data_2)
      this.selected_vouchers.push(data_2);
    }
    if (this.data.clients_seleted) {

      this.getInvoiceByClients();
    }
  }
  public pending_vouchers = []
  public selected_vouchers = []
  public view_paginator: ViewPaginator;
  public select_all:boolean=false;
  sumRemainingBalance(){
    let total = 0;
    if (!this.paginate) {
        if (this.dataGridInvoices && this.dataGridInvoices.selectedRowKeys) {
            const vouchers: PendingVouchers[] = this.dataGridInvoices.selectedRowKeys;
            for (const item of vouchers) {
              if (item.voucher_type == "Invoice") {
                total = total + item.remaining_balance
              } else {
                total = total - item.remaining_balance
              }
            }
          }
    }else{
        //debugger
        this.pending_vouchers.forEach(element => {
            //debugger
            if (element.voucher_type == "Invoice") {
                total = total + element.remaining_balance
              } else {
                total = total - element.remaining_balance
              }
        });
    }

    // total = Number((selected.map(t => (t.remaining_balance * 1)).reduce((acc, value) => ((acc + value) * 1), 0)).toFixed(2));
    return (total).toFixed(2);
  }

  changeBool(){
    debugger
    if (this.paginate) {
        this.paginate=false
    }else{
        this.paginate=true
    }
  }

  notification(masage: string,time:number=1000) {
    this._snackBar.open(masage, null, {
      duration: time
    });
  }

  getInvoiceByClients() {
    if (this.data.clients_seleted) {
      for (const item of this.data.clients_seleted) {
        item.date = moment(this.data.date).format('YYYY-MM-DD');
      }
      this.data.pending_vouchers = [];
      let filter = 'document='+this.filter.document +'&date='+ this.filter.date
      filter = this.data.exchange?filter+'&exchange=true':filter;
      filter=this.paginate?filter+'&paginate=true':filter;
      this.showSpinner=true;
      this.incomePaymentProofMainService.getInvoiceByClientsPaginate(this.data.enterprise, this.data.clients_seleted, filter).subscribe(
        (data: {invoice: ViewPaginator, credit_note: ViewPaginator, advances: ViewPaginator}) => {
          debugger;
          this.showSpinner=false;
          if(data.invoice && data.invoice.data && data.invoice.data.length > 0) {
            this.data.pending_vouchers = this.data.pending_vouchers.concat(data.invoice.data)
          }
          if(data.credit_note && data.credit_note.data && data.credit_note.data.length > 0) {
            this.data.pending_vouchers = this.data.pending_vouchers.concat(data.credit_note.data)

          }
          if(data.advances && data.advances.data && data.advances.data.length > 0) {
            this.data.pending_vouchers = this.data.pending_vouchers.concat(data.advances.data)
          }
          this.cdRef.detectChanges();
          this.printInitSelected();
          this.notification('Facturas Cargadas con exito')
          debugger
          console.log('pendding vouchers', this.pending_vouchers);
          const totalItems  =(data.invoice.total || 0) +(data.credit_note.total || 0) +(data.advances.total || 0);
          const perpage  =(data.invoice.per_page || 0) +(data.credit_note.per_page || 0) +(data.advances.per_page || 0);
          const currentTo =(data.invoice.to || 0) +(data.credit_note.to || 0) +(data.advances.to || 0);
          const lastPage = Math.ceil(totalItems / perpage); // Asume que toda
          this.view_paginator = data.invoice;
          this.view_paginator.to = currentTo
          this.view_paginator.last_page = lastPage
          this.view_paginator.total = totalItems

        },error=>{
            console.log(error);
            this.showSpinner=false;
          }
      )
    }

  }

  nextPreviousPage(url) {
    debugger
    for (const item of this.data.clients_seleted) {
      item.date = moment(this.data.date).format('YYYY-MM-DD');
    }
    this.data.pending_vouchers = [];
    let filter = '&document='+this.filter.document +'&date='+ this.filter.date
    filter = this.data.exchange?filter+'&exchange=true':filter;
    filter=this.paginate?filter+'&paginate=true':filter;
    this.showSpinner=true;
    this.incomePaymentProofMainService.getInvoiceByClientsPaginateNextPreviousPage(url+filter, this.data.clients_seleted).subscribe(
      (data: {invoice: ViewPaginator, credit_note: ViewPaginator, advances: ViewPaginator}) => {
        this.showSpinner=false;
          debugger
          if(data.invoice && data.invoice.data && data.invoice.data.length > 0) {
            this.data.pending_vouchers = this.data.pending_vouchers.concat(data.invoice.data)
          }
          if(data.credit_note && data.credit_note.data && data.credit_note.data.length > 0) {
            this.data.pending_vouchers = this.data.pending_vouchers.concat(data.credit_note.data)

          }
          if(data.advances && data.advances.data && data.advances.data.length > 0) {
            this.data.pending_vouchers = this.data.pending_vouchers.concat(data.advances.data)
          }
          this.printInitSelected();
        console.log('pendding vouchers', this.pending_vouchers);
        debugger
        console.log('pendding vouchers', this.pending_vouchers);
        const totalItems  =(data.invoice.total || 0) +(data.credit_note.total || 0) +(data.advances.total || 0);
        const perpage  =(data.invoice.per_page || 0) +(data.credit_note.per_page || 0) +(data.advances.per_page || 0);
        const currentTo =(data.invoice.to || 0) +(data.credit_note.to || 0) +(data.advances.to || 0);
        const lastPage = Math.ceil(totalItems / perpage); // Asume que toda
        this.view_paginator = data.invoice;
        this.view_paginator.to = currentTo
        this.view_paginator.last_page = lastPage
        this.view_paginator.total = totalItems
        this.cdRef.detectChanges();

      },error=>{
        console.log(error);
        this.showSpinner=false;
      }
    )
  }

  public check_all:boolean=false;
  changeall(event:any){
    debugger
    this.data.pending_vouchers.forEach(element=>{
        element.check=event.checked
        this.changeCheck(element)
    });
    this.cdRef.detectChanges();

  }

  changeCheck(data: PendingVouchers) {
    debugger
    if (data.check) {
      this.pending_vouchers.push(data)
      this.data.selected.push(data.ID)
    } else {
      let index = this.pending_vouchers.findIndex(v=> v.ID == data.ID)
      let index_selected = this.data.selected.findIndex(v=> v == data.ID)
      if (index >= 0) {
        this.pending_vouchers.splice(index, 1)
      }
      if (index_selected >= 0) {
        this.data.selected.splice(index_selected, 1)
      }
    }
  }

  public printInitSelected(): void {
    debugger
    if (this.paginate) {
        this.data.selected.forEach(element => {
            let index = this.data.pending_vouchers.findIndex(v=> v.ID == element)
            if (index >= 0) {
              this.data.pending_vouchers[index].check = true;
            }
          });

    }else{
        if (!this.data.selected) {
            this.data.selected = [];
          }
          if (!this.data.pending_vouchers){
            this.data.pending_vouchers = [];
          }
          this.selected_vouchers.forEach(voucher => {
            const item = this.data.pending_vouchers.find(i => i.ID == voucher.ID);
            const itemSelected = this.dataGridInvoices.selectedRowKeys.find(i => i.ID == voucher.ID);
            if (item && !itemSelected) {
              this.dataGridInvoices.selectedRowKeys.push(item);
            }
          });
    }

  }

  close() {
    debugger;
    if (!this.paginate) {
        this.data.pending_vouchers = this.dataGridInvoices.selectedRowKeys;
        this.data.selected = this.dataGridInvoices.selectedRowKeys.map(e => e.ID);
    }else{
        this.data.pending_vouchers = this.pending_vouchers
    }
    this.dialogRef.close(this.data);

  }

}
